module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-material-ui-virtual-af252457ae/3/buildhome/.yarn/berry/cache/gatsby-plugin-material-ui-npm-4.1.0-2938ee1c08-10c0.zip/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-29ddf81aa2/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
